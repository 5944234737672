import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import { Row } from "../pages"
import { color, View, Flex, radius, SVG } from "../components/Styles"
import SEO from "../components/SEO"
import { useTranslation } from "react-i18next"

import "./markdown.css"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { t } = useTranslation()

  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <Row>
        {frontmatter.path.includes("study") && (
          <Flex aic gap={3} mt={2}>
            <Link to="/study/">
              <Flex aic gap={0.75}>
                <SVG svg={back} fill={color.gray} />
                <View>{t("返回猎币学院")}</View>
              </Flex>
            </Link>
            <View display={["none", null]}>
              <Flex aic gap={0.75}>
                {frontmatter.tags.map(tag => (
                  <View
                    as="span"
                    p={0.5}
                    r={radius.md}
                    weight={"bold"}
                    color={color.liebi}
                    bg={color.washedGreen}
                  >
                    {t(tag)}
                  </View>
                ))}
              </Flex>
            </View>
          </Flex>
        )}

        <View scale={3} my={0.75} mr={1.5} weight={"bold"} paragraph>
          {frontmatter.title}
        </View>
      </Row>

      <Row mt={2.5} pt={2.5} mb={5} bt={color.gray3}>
        <section class="markdown-body">
          <View paragraph dangerouslySetInnerHTML={{ __html: html }} />
        </section>
      </Row>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        tags
      }
    }
  }
`

const back = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="12" height="12" fill="white" />
    <path d="M10.9482 10.6113C11.2588 10.6113 11.3994 10.4121 11.4521 10.1719C11.4873 9.99609 11.499 9.70312 11.499 9.36328C11.499 6.52734 10.3682 5.30859 7.58496 5.30859H3.49512L2.05957 5.39062L4.02832 3.58594L5.3291 2.26172C5.42285 2.16797 5.47559 2.0332 5.47559 1.89258C5.47559 1.59961 5.24707 1.38867 4.95996 1.38867C4.81934 1.38867 4.69629 1.43555 4.56152 1.56445L0.682617 5.4375C0.56543 5.54883 0.500977 5.68945 0.500977 5.83008C0.500977 5.97656 0.56543 6.11133 0.682617 6.22852L4.5791 10.1133C4.69629 10.2246 4.81934 10.2773 4.95996 10.2773C5.24707 10.2773 5.47559 10.0664 5.47559 9.77344C5.47559 9.63281 5.42285 9.49219 5.3291 9.39844L4.02832 8.07422L2.05371 6.26953L3.49512 6.35742H7.53223C9.68848 6.35742 10.4385 7.24219 10.4385 9.41602C10.4385 9.69141 10.4268 9.88477 10.4268 10.0898C10.4268 10.4004 10.6436 10.6113 10.9482 10.6113Z" />
  </svg>
)
